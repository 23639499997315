// Text reveal on load
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

("use strict");

document.querySelectorAll(".home__h1 span").forEach(function (span) {
  let text = span.textContent;
  span.innerHTML = "";
  text.split("").forEach(function (char) {
    let newSpan = document.createElement("span");
    newSpan.textContent = char;
    span.appendChild(newSpan);
  });
});

// document.querySelectorAll('.about__h2, .projects__h2').forEach(function(h2) {
//     let text = h2.textContent;
//     h2.innerHTML = ''; // Vider le contenu actuel
//     text.split(' ').forEach(function(word, index) {
//       let newSpan = document.createElement('span');
//       newSpan.textContent = word;
//       // newSpan.style.display = 'inline-block'; // S'assurer que chaque mot est inline-block
//       if (index > 0) h2.appendChild(document.createTextNode(' ')); // Ajouter un espace entre les mots
//       h2.appendChild(newSpan);
//     });
//   });

// Séparer chaque caractère en <span> tout en conservant les espaces
document.querySelectorAll(".projects__h2, .about__h2").forEach(function (h2) {
  let text = h2.textContent;
  h2.innerHTML = ""; // Vider le contenu actuel

  text.split("").forEach(function (char) {
    let newSpan = document.createElement("span");

    if (char === " ") {
      // Si le caractère est un espace, ajouter un espace insécable (&nbsp;)
      newSpan.innerHTML = "&nbsp;";
    } else {
      newSpan.textContent = char;
    }

    newSpan.style.display = "inline-block"; // Inline-block pour chaque caractère
    h2.appendChild(newSpan);
  });
});

gsap
  .timeline()
  .from(".home__h1--g span", {
    y: "100%",
    duration: 1,
    ease: "power4.out",
    stagger: 0.1,
  })
  .from(
    ".home__h1--m span",
    {
      y: "100%",
      duration: 1,
      ease: "power4.out",
      stagger: 0.1,
    },
    "-=1"
  );

gsap.timeline().from(".home__desc span", {
  y: 100,
  duration: 1,
  ease: "power4.out",
  delay: 1,
});
gsap.timeline().from(".home__cta span", {
  y: 100,
  duration: 1,
  ease: "power4.out",
  delay: 1,
});

gsap
  .timeline({
    scrollTrigger: {
      trigger: ".about__h2",
      start: "top 80%",
      once: true,
    },
  })
  .from(".about__h2 span", {
    y: "100%",
    duration: 1,
    ease: "power4.out",
    stagger: 0.05,
  });

gsap
  .timeline({
    scrollTrigger: {
      trigger: ".projects__h2",
      start: "top 80%",
      once: true,
    },
  })
  .from(".projects__h2 span", {
    y: "100%",
    duration: 1,
    ease: "power4.out",
    stagger: 0.05,
  });

// Scale-down ellipse on load

gsap.timeline().from(".bg-ellipse", {
  opacity: 0,
  scale: 3,
  duration: 3,
  ease: "power4.out",
});

// Show navbar on page load

gsap.timeline().to(".nav", {
  y: 0,
  duration: 2,
  ease: "power4.out",
});

// Hide navbar on scroll

const showAnim = gsap
  .from(".nav", {
    yPercent: -200,
    paused: true,
    duration: 0.2,
  })
  .progress(1);

ScrollTrigger.create({
  start: "top top",
  end: "max",
  onUpdate: (self) => {
    self.direction === -1 ? showAnim.play() : showAnim.reverse();
  },
});

function adjustClipBlobHeight() {
  const projects = document.querySelector(".projects");
  const footer = document.querySelector(".footer");
  const clipBlob = document.querySelector(".clip-blob");

  const projectsHeight = projects.offsetHeight;
  const footerHeight = footer.offsetHeight;

  // Ajuster la hauteur de clip-blob en fonction de projects et footer
  const totalHeight = projectsHeight + footerHeight;
  clipBlob.style.height = `${totalHeight}px`;
}

// Appeler la fonction au chargement et au redimensionnement de la fenêtre
window.addEventListener("load", adjustClipBlobHeight);
window.addEventListener("resize", adjustClipBlobHeight);

// Custom cursor

const cursor = document.getElementById('cursor');
const circle = document.getElementById('circle');

// Suivre les mouvements de la souris pour positionner le curseur personnalisé
document.addEventListener('mousemove', (event) => {
  cursor.style.left = (event.clientX - cursor.offsetWidth / 2) + 'px';
  cursor.style.top = (event.clientY - cursor.offsetHeight / 2) + 'px';
});

// Ajouter un effet lors du survol des liens
const links = document.querySelectorAll('a');

// Lorsqu'on survole un lien, le curseur devient plus grand
links.forEach(link => {
  link.addEventListener('mouseenter', () => {
    cursor.classList.add('link-hover');
  });
  
  link.addEventListener('mouseleave', () => {
    cursor.classList.remove('link-hover');
  });
});

document.addEventListener("mouseleave", function() {
  cursor.classList.add('cursor-leave');
  });

document.addEventListener("mouseenter", function() {
  cursor.classList.remove('cursor-leave');
});